import airbrake from '$lib/utils/airbrake';

airbrake.addFilter((notice) => {
  const newNotice = notice;
  newNotice.context.hooks = 'client';
  if (newNotice.context.environment === 'development') return null;

  return newNotice;
});

export const handleError = ({ error, event }) => {
  const errorId = window.crypto.randomUUID();
  airbrake.notify({
    error,
    params: { errorId, event },
  });
  return {
    message: error as any,
    errorId,
  };
};
